import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Navbar from './components/Navbar';
import Hero from './components/home/Hero';
import Highlights from './components/home/Highlights';
import Features from './components/home/Features';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import VideoGallery from './components/VideoGallery';
import Faq from './components/Faq';
import * as Sentry from '@sentry/react';

const Home = () => (
  <>
    <Helmet>
      <title>Home Page - aimyComfort home improvement</title>
      <meta name="description" content="Polyurethane product for home, office supplies, automotive interiors, pet products..." />
    </Helmet>

    <Hero />
    <Highlights />
    <Features />
    {/* <HowItWorks /> */}
  </>
);

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <main className="bg-white">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={
              <>
                <Helmet>
                  <title>About Us - aimyComfort home improvement</title>
                  <meta name="description" content="Learn about aimyComfort's mission, values, and innovative approach to home products." />
                </Helmet>
                <About />
              </>
            } />
            <Route path="/contact" element={
              <>
                <Helmet>
                  <title>Contact Us - aimyComfort home improvement</title>
                  <meta name="description" content="Get in touch with aimyComfort for all your home goods needs. We're here to help!" />
                </Helmet>
                <Contact />
              </>

            } />
            <Route path="/videos" element={
              <>
                <Helmet>
                  <title>Video Gallery - aimyComfort home improvement</title>
                  <meta name="description" content="Watch our product demos and how-to videos for aimyComfort home improvement solutions." />
                </Helmet>
                <VideoGallery />
              </>

            } />
            <Route path="/faq" element={
              <>
                <Helmet>
                  <title>FAQ - aimyComfort home improvement</title>
                  <meta name="description" content="Find answers to frequently asked questions about aimyComfort products and services." />
                </Helmet>
                <Faq />
              </>
            } />
          </Routes>
          <Footer />
        </main>
      </Router>
    </HelmetProvider>
  );
};

export default Sentry.withProfiler(App);
